<template>
    <div>
        <div class="row">
            <div class="col-md-12 workflows-table">
                <!-- <KTCodePreview v-bind:title="'Spam'"> -->
                <br />
                <v-app style="min-height: auto" id="spam-form">
                    <v-card>
                        <v-card-title>
                            Reguli
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-select
                                v-model="departments"
                                :items="departmentss"
                                label="Departament"
                                hide-details
                                @change="chooseDepart"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                label="Cauta"
                                single-line
                                hide-details
                            ></v-text-field>
                            <v-btn @click="searchWorkflow"> Cauta </v-btn>
                            <v-spacer></v-spacer>
                            <AddGeneralRule />
                        </v-card-title>
                        <v-data-table
                            :page="page"
                            :pageCount="numberOfPages"
                            :headers="headers"
                            :items="workflows"
                            :options.sync="options"
                            :server-items-length="totalWorkflows"
                            item-key="id"
                            class="elevation-1"
                            :footer-props="{ 'items-per-page-options': [10, 15, 25] }"
                            :loading="loading"
                            loading-text="Se incarca mail-uri... "
                        >
                            <template v-slot:top>
                                <EditRuleDialog
                                    :showItem="showItem"
                                    :dialog="dialog"
                                    @close-dialog="closeDialog"
                                />
                            </template>
                            <template v-slot:item.name="{ item }">
                                <span @click="showWorkflow(item)" style="cursor: pointer">
                                    {{ item.name }}
                                </span>
                            </template>
                            <template v-slot:actiuni="{ item }">
                                <span @click="showWorkflow(item)" style="cursor: pointer">
                                    {{ item.name }}
                                </span>
                            </template>
                            <template v-slot:item.created_by="{ item }">
                                {{ item.created_by ? item.created_by.fullName : "" }}
                            </template>
                            <template v-slot:item.active="{ item }">
                                <v-chip
                                    :color="item.active ? 'green' : 'red'"
                                    dark
                                    x-small
                                >
                                    {{ item.active ? "activ" : "inactiv" }}
                                </v-chip>
                            </template>
                            <template v-slot:no-data>
                                <div>Nu exista mail-uri</div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-app>
                <!-- </KTCodePreview> -->
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";
import EditRuleDialog from "@/view/pages/workflows/EditRuleDialog.vue";
import AddGeneralRule from "@/view/pages/conversation/AddGeneralRule";

export default {
    data() {
        return {
            search: "",
            page: 1,
            numberOfPages: 0,
            options: {
                itemsPerPage: 10,
            },
            totalWorkflows: 0,
            loading: false,
            departments: null,
            departs: [],
            dialog: false,
            headers: [
                {
                    text: "Email client",
                    align: "start",
                    sortable: false,
                    value: "name",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Departament",
                    value: "mailbox",
                    sortable: false,
                    class: "h6 grey lighten-2",
                    width: "10%",
                },
                {
                    text: "Creata de",
                    value: "created_by",
                    sortable: false,
                    width: "10%",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Status",
                    sortable: false,
                    value: "active",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Actiuni",
                    sortable: false,
                    value: "actiuni",
                    class: "h6 grey lighten-2",
                },
            ],
            items: ["Toate"],
            showItem: {},
        };
    },
    components: {
        EditRuleDialog,
        AddGeneralRule,
    },
    watch: {
        // workflows() {
        //     this.workflows.map((workflow) => {
        //         console.log(workflow);
        //         if (workflow.active) {
        //             if (!this.items.includes(workflow.mailbox)) {
        //                 this.items.push(workflow.mailbox);
        //             }
        //         }
        //     });
        // },

        options: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },
    computed: {
        workflows() {
            return this.$store.getters["mails/workflows"];
        },
        departmentss() {
            const departments = this.$store.getters["departments/departments"];
            let eachDepartmentOptions = [{ value: null, text: "Toate" }];
            let onlyDeps = null;
            departments.forEach((dep) => {
                eachDepartmentOptions.push({ value: dep.id, text: dep.name });
            });
            return eachDepartmentOptions;
        },
    },
    methods: {
        showWorkflow(item) {
            // this.showItem = this.workflows.indexOf(item);
            this.showItem = Object.assign({}, item);
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        deleteRule(rule) {
            this.$store.dispatch(DELETE_WORKFLOW, rule.id).then(() => {
                this.$store.dispatch("mails/getWorkflows");
                this.dialog = false;
            });
        },
        chooseDepart() {
            this.resetPage();
            this.getData();
        },
        searchWorkflow() {
            this.resetPage();
            this.getData();
        },
        resetPage() {
            if (this.options.page !== 1) {
                this.options.page = 1;
            }
        },
        getData() {
            this.loading = true;

            let payload = {
                // search: this.form.searchkey,
                per_page: this.options.itemsPerPage,
                page: this.options.page,
            };

            if (this.departments) {
                payload.mailbox_id = this.departments;
            }

            if (this.search) {
                payload.search = this.search;
            }

            this.$store.dispatch("mails/getWorkflows", payload).then((response) => {
                if (response.automatic.length) {
                    this.loading = false;
                    this.totalWorkflows = response.page.totalElements;
                    this.numberOfPages = response.page.totalPages;
                } else {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Workflow - lista reguli" }]);
    },
    created() {
        this.$store.dispatch("departments/getDepartments");
        // this.$store.dispatch("mails/getWorkflows");
    },
};
</script>

<style>
.workflows-table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
